<template>
  <div class="qz-service-page">
    <div class="qz-service-page__header">
      <div class="title">After-sales service</div>
      <div class="subtitle">售后服务</div>
    </div>
    <div class="form-wrap">
      <service-form></service-form>
    </div>
  </div>
</template>

<script>
import ServiceForm from "@/components/ServiceForm.vue";
export default {
  name: "QzService",
  components: {
    "service-form": ServiceForm
  },
}
</script>

<style lang="scss" scoped>
.qz-service-page {
  .qz-service-page__header {
    height: 50px;
    padding: 0 20px;
    background-color: #5a1b1b;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      text-transform: uppercase;
    }
    .subtitle {
      font-size: 12px;
      letter-spacing: 3px;
    }
  }
  .form-wrap {
    padding: 46px 50px 60px;
  }
}
</style>